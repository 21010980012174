import React from "react";

class Settings extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row no-gutters mb-2">
          <div className="col mt-4">
            <h4>Settings</h4>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <center className="text-muted">
                  <br />
                  <h5>Settings go here</h5>
                  Use the showSettingsGear prop in AppView to turn this off if
                  you don&apos;t need it.
                  <br />
                  <br />
                  <br />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
