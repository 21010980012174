/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import MonthYearSelect from "./MonthYearSelect";

import Form from "@ukhc/devops-react-library/components/Form";

var XLSX = require("xlsx");

const aedTableHeaders = [
  { label: "Checklist submitted for the month", key: "checklistSubmitted" },
  { label: "% compliance for month", key: "pctCompliance" },
];

const emergencySupplyTableHeaders = [
  {
    label: "# of days checked at least once, 5 day operation",
    key: "daysCheckedAtLeastOnce5DayOperation",
  },
  {
    label: "# of days checked each shift, 5 day operation",
    key: "daysCheckEachShift5DayOperation",
  },
  {
    label: "# of days NOT checked at all, 5 day operation",
    key: "daysNotChecked5DayOperation",
  },
  {
    label: "# of days checked at least once, 7 day operation",
    key: "daysCheckedAtLeastOnce7DayOperation",
  },
  {
    label: "# of days checked each shift, 7 day operation",
    key: "daysCheckEachShift7DayOperation",
  },
  {
    label: "# of days NOT checked at all, 7 day operation",
    key: "daysNotChecked7DayOperation",
  },
  {
    label: "% compliance for # of days checked, 5 day operation",
    key: "pctComplianceDaysChecked5DayOperation",
  },
  {
    label: "% compliance for # of days checked once, 5 day operation",
    key: "pctComplianceDaysCheckedOnce5DayOperation",
  },
  {
    label: "% compliance for # of days checked, 7 day operation",
    key: "pctComplianceDaysChecked7DayOperation",
  },
  {
    label: "% compliance for # of days checked once, 7 day operation",
    key: "pctComplianceDaysCheckedOnce7DayOperation",
  },
];

function GenerateReportModal({ ...props }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedChecklist, setSelectedChecklist] = useState("");
  const [fetchResponse, setFetchResponse] = useState({
    statusCode: null,
    message: null,
  });
  const [reportTable, setReportTable] = useState(null);
  const [buildingReportTable, setBuildingReportTable] = useState(false);
  const [fromDateMonth, setFromDateMonth] = useState(null);
  const [fromDateYear, setFromDateYear] = useState(null);
  const [toDateMonth, setToDateMonth] = useState(null);
  const [toDateYear, setToDateYear] = useState(null);

  useEffect(() => {
    if (reportTable !== null) {
      exportReport();
    }
  }, [reportTable]);

  useEffect(() => {
    if (fromDateMonth !== null && fromDateYear !== null) {
      setFromDate(`${fromDateMonth}/01/${fromDateYear}`);
    }
  }, [fromDateMonth, fromDateYear]);

  useEffect(() => {
    if (toDateMonth !== null && toDateYear !== null) {
      setToDate(`${toDateMonth}/01/${toDateYear}`);
    }
  }, [toDateMonth, toDateYear]);

  function formatAsPercentage(percent) {
    return String(percent.toFixed(2)) + "%";
  }

  function handleCloseModal() {
    document.getElementById("closeGenerateReportModalButton").click();
    props.onHide();
  }

  function exportReport() {
    const table = document.getElementById("table2Excel");
    const wb = XLSX.utils.table_to_book(table, { raw: true });
    XLSX.writeFile(
      wb,
      `${
        selectedChecklist === "aedchecklists"
          ? "AED Checklist"
          : "Emergency Supply Checklist"
      }.xlsx`
    );
    setReportTable(null);
    setBuildingReportTable(false);
    handleCloseModal();
  }

  function handleOnFormChange(property, value) {
    if (property === "report") {
      setSelectedChecklist(value);
    }

    if (property === "fromMonth") {
      setFromDateMonth(value);
    }

    if (property === "fromYear") {
      setFromDateYear(value);
    }

    if (property === "toMonth") {
      setToDateMonth(value);
    }

    if (property === "toYear") {
      setToDateYear(value);
    }
  }

  function createReportTable(data, monthsToReport, fieldsToReport) {
    const table = (
      <table className="d-none" id="table2Excel">
        <tbody>
          <tr>
            <td></td>
            {monthsToReport.map((month) => {
              return (
                <td key={month} colSpan={fieldsToReport.length}>
                  {DateTime.fromISO(month).toFormat("LLL-yy")}
                </td>
              );
            })}
          </tr>
          <tr>
            <td>Unit</td>
            {monthsToReport.map(() => {
              return fieldsToReport.map((header) => {
                return <td key={header.key}>{header.label}</td>;
              });
            })}
          </tr>
          {data.locations.map((location) => {
            return (
              <>
                <tr key={location.location}>
                  <td
                    colSpan={monthsToReport.length * fieldsToReport.length + 1}
                  >
                    {location.location}
                  </td>
                </tr>
                {location.units.map((unit) => {
                  return (
                    <tr key={`${unit.unit}${new Date().getTime()}`}>
                      <td>{unit.unit}</td>
                      {unit.months.map((month) => {
                        if (selectedChecklist === "emergencysupplychecklists") {
                          return (
                            <>
                              <td>
                                {month.daysCheckedAtLeastOnce5DayOperation}
                              </td>
                              <td>{month.daysCheckEachShift5DayOperation}</td>
                              <td>{month.daysNotChecked5DayOperation}</td>
                              <td>
                                {month.daysCheckedAtLeastOnce7DayOperation}
                              </td>
                              <td>{month.daysCheckEachShift7DayOperation}</td>
                              <td>{month.daysNotChecked7DayOperation}</td>
                              <td>
                                {formatAsPercentage(
                                  month.pctComplianceDaysChecked5DayOperation
                                )}
                              </td>
                              <td>
                                {formatAsPercentage(
                                  month.pctComplianceDaysCheckedOnce5DayOperation
                                )}
                              </td>
                              <td>
                                {formatAsPercentage(
                                  month.pctComplianceDaysChecked7DayOperation
                                )}
                              </td>
                              <td>
                                {formatAsPercentage(
                                  month.pctComplianceDaysCheckedOnce7DayOperation
                                )}
                              </td>
                            </>
                          );
                        }
                        if (selectedChecklist === "aedchecklists") {
                          return (
                            <>
                              <td>{month.checklistSubmitted ? "Yes" : "No"}</td>
                              <td>{formatAsPercentage(month.pctCompliance)}</td>
                            </>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </>
            );
          })}
        </tbody>
      </table>
    );
    setReportTable(table);
  }

  async function handleFormSubmit() {
    if (window.REACT_APP_DEBUG) console.log("Records: handleFetch");

    setBuildingReportTable(true);

    const fetchUrl = new URL(
      `${window.REACT_APP_API_URL}/${selectedChecklist}/report`
    );

    // Filtering
    if (fromDate !== null) {
      fetchUrl.searchParams.set("fromDate", fromDate);
    }

    if (toDate !== null) {
      fetchUrl.searchParams.set("toDate", toDate);
    }

    fetchUrl.toString();

    if (window.REACT_APP_DEBUG) console.log("Records: " + fetchUrl);

    const fetchOptions = {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        setFetchResponse({
          statusCode: response.status,
          message: null,
        });
        throw new Error(response.status);
      }

      if (window.REACT_APP_DEBUG)
        console.log("Records fetch response.status: " + response.status);

      setFetchResponse({
        statusCode: response.status,
        message: null,
      });

      const data = await response.json();

      if (Object.keys(data).length > 0) {
        var monthsArray = [];
        data.locations[0].units[0].months.forEach((month) => {
          var monthSplit = month.month.split("T");
          monthsArray.push(monthSplit[0]);
        });

        let fieldsArray = [];
        if (selectedChecklist === "aedchecklists") {
          fieldsArray = aedTableHeaders;
        }
        if (selectedChecklist === "emergencysupplychecklists") {
          fieldsArray = emergencySupplyTableHeaders;
        }

        createReportTable(data, monthsArray, fieldsArray);
      }
    } catch (error) {
      if (window.REACT_APP_DEBUG) console.log("Records error: " + error);
      if (window.REACT_APP_DEBUG) console.dir(error);

      // Handle missing API (no response)
      if (error.message && error.message === "404") {
        setFetchResponse({
          statusCode: 404,
          message: "No inspections found.",
        });
      }
      setBuildingReportTable(false);
    }
  }

  const reportSettings = {
    rows: [
      {
        cols: [
          {
            controls: [
              {
                label: "Report",
                id: "report",
                type: "select",
                disabled: buildingReportTable,
                helpText: "Select a report to generate.",
                showHelpButton: true,
                property: "report",
                required: true,
                options: [
                  { label: "Select report", value: "" },
                  { label: "AED Checklist", value: "aedchecklists" },
                  {
                    label: "Emergency Supply Checklist",
                    value: "emergencysupplychecklists",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                type: "custom",
                id: "fromDate",
                property: "fromDate",
                component: (
                  <MonthYearSelect
                    onChange={handleOnFormChange}
                    label="From"
                    id="from"
                    disabled={buildingReportTable}
                    required={true}
                  />
                ),
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                label: "To",
                type: "custom",
                id: "toDate",
                property: "toDate",
                disabled: buildingReportTable,
                required: true,
                component: (
                  <MonthYearSelect
                    onChange={handleOnFormChange}
                    label="To"
                    id="to"
                    disabled={buildingReportTable}
                    required={true}
                  />
                ),
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <div
      className="modal fade"
      id="generateReportModal"
      role="dialog"
      aria-labelledby="generateReportModalCenterTitle"
      aria-hidden="true"
      onClick={handleCloseModal}
    >
      <div
        className="modal-dialog modal-dialog-centered"
        role="document"
        onClick={(e) => e.stopPropagation()}
        aria-hidden="true"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="generateReportModalLongTitle">
              Generate Report
            </h5>
            <button
              type="button"
              id="closeGenerateReportModalButton"
              className="close"
              onClick={handleCloseModal}
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {buildingReportTable && (
              <div className="alert alert-primary">
                <div
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                ></div>
                Generating report...
              </div>
            )}
            <Form
              controls={reportSettings}
              onChange={handleOnFormChange}
              onSubmit={handleFormSubmit}
              submitButtonLabel="Generate Report"
            />
            {reportTable}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateReportModal;
