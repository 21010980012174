/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Form from "@ukhc/devops-react-library/components/Form";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";

import { DateTime } from "luxon";

function EmergencySupplyChecklistUpdateCard({ ...props }) {
  const locations = props.locations;

  const [requireMissingAEDNoteField, setRequireMissingAEDNoteField] =
    useState(false);

  if (window.REACT_APP_DEBUG) {
    console.log("selectedLocation", props.selectedLocation);
    console.log("selectedUnit", props.selectedUnit);
  }

  const record = {
    unit: props.selectedUnit ? String(props.selectedUnit.value) : "",
    location: props.selectedLocation
      ? String(props.selectedLocation.value)
      : "",
  };

  const [primaryInspectionDetails, setPrimaryInspectionDetails] = useState({
    date: null,
    operation: null,
    shift: null,
    location: locations.find(
      (location) => String(location.value) === String(record.location)
    ).label,
    unit: record.unit,
  });

  const [duplicateInspectionFound, setDuplicateInspectionFound] =
    useState(false);

  useEffect(() => {
    if (
      primaryInspectionDetails.location !== "" &&
      primaryInspectionDetails.unit !== "" &&
      primaryInspectionDetails.date !== null
    ) {
      checkForDuplicateInspection();
    }
  }, [primaryInspectionDetails]);

  async function checkForDuplicateInspection() {
    if (window.REACT_APP_DEBUG)
      console.log(
        "EmergencySupplyChecklistUpdateCard: checkForDuplicateInspection"
      );

    const fetchUrl = new URL(
      window.REACT_APP_API_URL + "/emergencysupplychecklists"
    );

    // Filtering
    if (primaryInspectionDetails.location !== "") {
      fetchUrl.searchParams.set("location", primaryInspectionDetails.location);
    } else {
      fetchUrl.searchParams.delete("location");
    }

    if (primaryInspectionDetails.unit !== "") {
      fetchUrl.searchParams.set("unit", primaryInspectionDetails.unit);
    } else {
      fetchUrl.searchParams.delete("unit");
    }

    // Filter by date
    if (primaryInspectionDetails.date !== null) {
      fetchUrl.searchParams.set("date", primaryInspectionDetails.date);
    } else {
      fetchUrl.searchParams.delete("date");
    }

    if (primaryInspectionDetails.operation !== "") {
      fetchUrl.searchParams.set(
        "operation",
        primaryInspectionDetails.operation
      );
    } else {
      fetchUrl.searchParams.delete("operation");
    }

    if (primaryInspectionDetails.shift !== "") {
      fetchUrl.searchParams.set("shift", primaryInspectionDetails.shift);
    } else {
      fetchUrl.searchParams.delete("shift");
    }

    fetchUrl.toString();

    if (window.REACT_APP_DEBUG) console.log("Records: " + fetchUrl);

    const fetchOptions = {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const data = await response.json();
      if (data.length > 0) {
        setDuplicateInspectionFound(true);
      }
    } catch (error) {
      if (window.REACT_APP_DEBUG) console.log("Records error: " + error);
      if (window.REACT_APP_DEBUG) console.dir(error);

      // Handle missing API (no response)
      if (error.message && error.message === "404") {
        setDuplicateInspectionFound(false);
      }
    }
  }

  var formControls = {
    rows: [
      {
        cols: [
          {
            controls: [
              {
                type: "custom",
                component: (
                  <div
                    className={`${
                      duplicateInspectionFound ? "d-block" : "d-none"
                    } alert alert-danger mb-2 py-1`}
                  >
                    <small>
                      Only one inspection can be submitted for an operation and
                      shift each day.
                    </small>
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                id: "date",
                label: "Date",
                property: "date",
                type: "date",
                required: true,
              },
              {
                label: "Operation",
                property: "operation",
                id: "operation",
                type: "select",
                required: true,
                options: [
                  { value: "", label: "Select operation" },
                  { value: "5 day", label: "5 day" },
                  { value: "7 day", label: "7 day" },
                ],
              },
              {
                label: "Shift",
                property: "shift",
                id: "shift",
                type: "select",
                required: true,
                options: [
                  { value: "", label: "Select shift" },
                  { value: "7A", label: "7A" },
                  { value: "7P", label: "7P" },
                ],
              },
              {
                label: "Location",
                property: "location",
                id: "location",
                type: "select",
                options: props.locations,
                required: true,
              },
              {
                label: "Unit",
                property: "unit",
                id: "unit",
                type: "select",
                options: props.units,
                disabled: props.isFetchingUnits,
                required: true,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            // label: "Zoll",     // CC-24
            label: "LifePak", // CC-24
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              // CC-24 - delete zollPropertyTag - begin
              /*
              {
                property: "zollPropertyTag",
                id: "zollPropertyTag",
                type: "text",
                required: true,
                maxLength: 35,
                label: "Zoll Property Tag #",
              },
              */
              // CC-24 - delete zollPropertyTag - end
              {
                property: "cablesPresentAndStored",
                id: "cablesPresentAndStoredCompliance",
                label: "All cables are present and properly stored",
                type: "radio",
                required: true,
                name: "cablesPresentAndStoredCompliance",
                inline: true,
                options: [
                  {
                    id: "cablesPresentAndStoredCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "cablesPresentAndStoredNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              // CC-24 - add recordPropertyTag - begin
              {
                property: "recordPropertyTag",
                id: "recordPropertyTagCompliance",
                label: "Record Property tag # above",
                type: "radio",
                required: true,
                name: "recordPropertyTagCompliance",
                inline: true,
                options: [
                  {
                    id: "recordPropertyTagCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "recordPropertyTagNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              // CC-24 - add recordPropertyTag - end
              // CC-24 - add padsRemainInPackage - begin
              {
                property: "padsRemainInPackage",
                id: "padsRemainInPackageCompliance",
                label:
                  "Pads should remain in package, unopened until ready to use",
                type: "radio",
                required: true,
                name: "padsRemainInPackageCompliance",
                inline: true,
                options: [
                  {
                    id: "padsRemainInPackageCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "padsRemainInPackageNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              // CC-24 - add padsRemainInPackage - end
              // CC-24 - delete statPads - begin
              /*
              {
                property: "statPads",
                id: "statPadsCompliance",
                label:
                  "Adult carts should have STAT pads connected to the therapy cable",
                type: "radio",
                required: true,
                name: "statPadsCompliance",
                inline: true,
                options: [
                  {
                    id: "statPadsCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "statPadsNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              */
              // CC-24 - delete statPads - end
            ],
          },
        ],
      },
      {
        cols: [
          {
            label: "Defibrillator Check",
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              // CC-24 - delete charged - begin
              /*
              {
                property: "charged",
                id: "chargedCompliance",
                label: "Charged/checked PLUGGED AND UNPLUGGED",
                type: "radio",
                required: true,
                name: "chargedCompliance",
                inline: true,
                options: [
                  {
                    id: "chargedCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "chargedNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              */
              // CC-24 - delete charged - end
              // CC-24 - add defibrillatorIsPlugged - begin
              {
                property: "defibrillatorIsPlugged",
                id: "defibrillatorIsPluggedCompliance",
                label: "PLUGGED in an emergency plug (red outlet)",
                type: "radio",
                required: true,
                name: "defibrillatorIsPluggedCompliance",
                inline: true,
                options: [
                  {
                    id: "adefibrillatorIsPluggedCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "defibrillatorIsPluggedNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              // CC-24 - add defibrillatorIsPlugged - end
              // CC-24 - add defibrillatorIsFunctional - begin
              {
                property: "defibrillatorIsFunctional",
                id: "defibrillatorIsFunctionalCompliance",
                label:
                  "Defibrillator unit is fully functional; no broken or missing parts on visual inspection",
                type: "radio",
                required: true,
                name: "defibrillatorIsFunctionalCompliance",
                inline: true,
                options: [
                  {
                    id: "defibrillatorIsFunctionalCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "defibrillatorIsFunctionalNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              // CC-24 - add defibrillatorIsFunctional - end
              {
                property: "appropriateCabledConnected",
                id: "appropriateCabledConnectedCompliance",
                label: "All appropriate cables are connected correctly",
                type: "radio",
                required: true,
                name: "appropriateCabledConnectedCompliance",
                inline: true,
                options: [
                  {
                    id: "appropriateCabledConnectedCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "appropriateCabledConnectedNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              // CC-24 - delete defibrilatorPads - begin
              /*
              {
                property: "defibrilatorPads",
                id: "defibrilatorPadsCompliance",
                label:
                  "Defibrillator pads are present on the cart and connected to the therapy cable",
                type: "radio",
                required: true,
                name: "defibrilatorPadsCompliance",
                inline: true,
                options: [
                  {
                    id: "defibrilatorPadsCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "defibrilatorPadsNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              */
              // CC-24 - delete defibrilatorPads - end
            ],
          },
        ],
      },
      // CC-24 - add Lifepak - begin
      {
        cols: [
          {
            label:
              "Lifepak Defibrillator Check Instructions (check unplugged from wall)",
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                component: (
                  <>
                    <ul
                      className="text-dark font-weight-bold mb-0"
                      style={{ fontSize: 13 }}
                    >
                      <li>
                        1. Connect defibrillator therapy cable to Test Load box
                      </li>
                      <li>
                        2. Ensure cable is connected to Test Load and not the
                        patient
                      </li>
                      <li>
                        {
                          "3. Select 200J, charge, shock. Confirm 'Energy Delivered' appears"
                        }
                      </li>
                      <li>4. Remove Test Load from cable</li>
                      <li>
                        {
                          "5. Internal check: Press 'Options', 'User Test' & confirm test printed"
                        }
                      </li>
                    </ul>
                    <hr />
                  </>
                ),
                property: "lifepakInstructionsText",
                type: "custom",
              },
            ],
          },
        ],
      },
      // CC-24 - add Lifepak - end
      {
        cols: [
          {
            label: "Code Cart Check",
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                type: "text",
                property: "codeCartSerialNumber",
                id: "codeCartSerialNumber",
                maxLength: 35,
                required: true,
                label: "Serial Number 2 code cart locks",
              },
              {
                type: "text",
                property: "topDrawerSerialNumber",
                id: "topDrawerSerialNumber",
                maxLength: 35,
                required: true,
                label: "Top drawer (medication) lock serial #",
              },
              {
                id: "cartExpirationDate",
                label: "Expiration Date of Cart",
                property: "cartExpirationDate",
                type: "date",
                required: true,
              },
              {
                property: "expirationDateCheck",
                id: "expirationDateCheckCompliance",
                label:
                  "Expiration dates are checked and greater than 1 month for materials",
                type: "radio",
                required: true,
                name: "expirationDateCheckCompliance",
                inline: true,
                options: [
                  {
                    id: "expirationDateCheckCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "expirationDateCheckNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              {
                property: "cardiacBoard",
                id: "cardiacBoardCompliance",
                label: "Presence of Cardiac Board",
                type: "radio",
                required: true,
                name: "cardiacBoardCompliance",
                inline: true,
                options: [
                  {
                    id: "cardiacBoardCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "cardiacBoardNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              {
                property: "fullOxygenTank",
                id: "fullOxygenTankCompliance",
                label: "Presence of FULL Oxygen Tank",
                type: "radio",
                required: true,
                name: "fullOxygenTankCompliance",
                inline: true,
                options: [
                  {
                    id: "fullOxygenTankCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "fullOxygenTankNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              {
                property: "oxygenRegulator",
                id: "oxygenRegulatorCompliance",
                label: "Presence of Oxygen Regulator",
                type: "radio",
                required: true,
                name: "oxygenRegulatorCompliance",
                inline: true,
                options: [
                  {
                    id: "oxygenRegulatorCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "oxygenRegulatorNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              {
                property: "codeBlueForm",
                id: "codeBlueFormCompliance",
                label: "Presence of Code Blue form",
                type: "radio",
                required: true,
                name: "codeBlueFormCompliance",
                inline: true,
                options: [
                  {
                    id: "codeBlueFormCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "codeBlueFormNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              {
                property: "algorithm",
                id: "algorithmCompliance",
                label: "Presence of Algorithms",
                type: "radio",
                required: true,
                name: "algorithmCompliance",
                inline: true,
                options: [
                  {
                    id: "algorithmCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "algorithmNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
              {
                property: "suctionSetUp",
                id: "suctionSetUpCompliance",
                label: "Presence of suction set-up bottom of cart",
                type: "radio",
                required: true,
                name: "suctionSetUpCompliance",
                inline: true,
                options: [
                  {
                    id: "suctionSetUpCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "suctionSetUpNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            label: "Drug/Airway Box",
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                type: "text",
                property: "airwayLockSerialNumber",
                id: "airwayLockSerialNumber",
                maxLength: 35,
                required: true,
                label: "Serial Number 2 drug and airway locks",
              },
              {
                property: "airwayLocksExpirationDate",
                id: "airwayLocksExpirationDateCompliance",
                label: "Expiration date within 2 weeks for drug tray",
                type: "radio",
                required: true,
                name: "airwayLocksExpirationDateCompliance",
                inline: true,
                options: [
                  {
                    id: "airwayLocksExpirationDateCompliant",
                    label: "Compliant",
                    value: "true",
                  },
                  {
                    id: "airwayLocksExpirationDateNonCompliant",
                    label: "Non-Compliant",
                    value: "false",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const [fetchResponse, setFetchResponse] = useState({
    statusCode: null,
  });

  async function handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    } else {
      form.classList.add("was-validated");
    }

    if (window.REACT_APP_DEBUG) console.log("event.target", event.target);

    const fetchUrl = window.REACT_APP_API_URL + "/emergencysupplychecklists";

    const fetchOptions = {
      method: "POST",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify({
        date: event.target.date.value,
        operation: event.target.operation.value,
        shift: event.target.shift.value,
        unit: event.target.unit.value,
        location: event.target.location.selectedOptions[0].label,
        // zollPropertyTag: event.target.zollPropertyTag.value,                                               // CC-24 - delete
        cablesPresentAndStored:
          event.target.cablesPresentAndStored.value === "true" ? true : false,
        recordPropertyTag: event.target.recordPropertyTag.value, // CC-24 - add
        padsRemainInPackage: event.target.padsRemainInPackage.value, // CC-24 - add
        // statPads: event.target.statPads.value === "true" ? true : false,                                   // CC-24 - delete
        // charged: event.target.charged.value === "true" ? true : false,                                     // CC-24 - delete
        defibrillatorIsPlugged: event.target.defibrillatorIsPlugged.value, // CC-24 - add
        defibrillatorIsFunctional: event.target.defibrillatorIsFunctional.value, // CC-24 - add
        appropriateCabledConnected:
          event.target.appropriateCabledConnected.value === "true"
            ? true
            : false,
        // defibrilatorPads: event.target.defibrilatorPads.value === "true" ? true : false,                   // CC-24 - delete
        codeCartSerialNumber: event.target.codeCartSerialNumber.value,
        topDrawerSerialNumber: event.target.topDrawerSerialNumber.value,
        cartExpirationDate: event.target.cartExpirationDate.value,
        expirationDateCheck:
          event.target.expirationDateCheck.value === "true" ? true : false,
        cardiacBoard: event.target.cardiacBoard.value === "true" ? true : false,
        fullOxygenTank:
          event.target.fullOxygenTank.value === "true" ? true : false,
        oxygenRegulator:
          event.target.oxygenRegulator.value === "true" ? true : false,
        codeBlueForm: event.target.codeBlueForm.value === "true" ? true : false,
        algorithm: event.target.algorithm.value === "true" ? true : false,
        suctionSetUp: event.target.suctionSetUp.value === "true" ? true : false,
        airwayLockSerialNumber: event.target.airwayLockSerialNumber.value,
        airwayLocksExpirationDate:
          event.target.airwayLocksExpirationDate.value === "true"
            ? true
            : false,
        dateTimeSubmitted: DateTime.now(),
      }),
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);
      const data = await response.json();

      if (!response.ok) {
        this.setState({ httpResponseStatusCode: response.status });
        throw Error(response.status);
      }

      setFetchResponse({
        statusCode: response.status,
      });
      handleCloseForm();

      return data;
    } catch (error) {
      if (window.REACT_APP_DEBUG)
        console.log("Emergency Supply Checklist Add error: " + error);
      if (window.REACT_APP_DEBUG) console.dir(error);
    }
  }

  function handleCloseForm() {
    props.onHide();
  }

  function handleFormDataChange(property, value) {
    if (window.REACT_APP_DEBUG) {
      console.log("property: " + property + " value: " + value);
    }

    if (property === "location") {
      props.handleFetchUnits("emergencysupplychecklists", value);
      let selectedLocation = locations.find(
        (location) => String(location.value) === String(value)
      );
      setPrimaryInspectionDetails((details) => ({
        ...details,
        location: selectedLocation.label,
      }));
    }

    if (property === "unit") {
      setPrimaryInspectionDetails((details) => ({
        ...details,
        unit: value,
      }));
    }

    if (property === "date") {
      setPrimaryInspectionDetails((details) => ({
        ...details,
        date: value,
      }));
    }

    if (property === "operation") {
      setPrimaryInspectionDetails((details) => ({
        ...details,
        operation: value,
      }));
    }

    if (property === "shift") {
      setPrimaryInspectionDetails((details) => ({
        ...details,
        shift: value,
      }));
    }
  }

  return (
    <div className="card mb-3">
      <h5 className="card-header">Add Emergency Supply Checklist Inspection</h5>
      <div className="card-body">
        <Form
          id="emergencySupplyChecklistAdd-form"
          onChange={handleFormDataChange}
          controls={formControls}
          onCancel={handleCloseForm}
          onSubmit={handleSubmit}
          submitButtonLabel="Submit"
          record={record}
        />
        {fetchResponse.statusCode > 399 && (
          <ResponseStatusAlert statusCode={fetchResponse.statusCode} />
        )}
      </div>
    </div>
  );
}

export default EmergencySupplyChecklistUpdateCard;
