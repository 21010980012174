// @ukhc/devops-react-library
import Table from "@ukhc/devops-react-library/components/Table";

function AEDChecklistTable({ ...props }) {
  return (
    <>
      <div className="alert alert-primary px-3 py-1 d-inline-block">
        <small>
          Inspections should be conducted before the fifth business day of each
          month.
        </small>
      </div>
      <Table
        checkboxSelectOnly={false}
        columns={props.tableColumns}
        disabled={props.isTableDisabled}
        handleSetSelectedRows={props.handleSetSelectedRows}
        isBordered={false}
        isLoading={props.isLoading}
        isMultiSelect={false}
        isSmall={true}
        records={props.records}
        responseStatusCode={props.fetchResponseStatusCode}
        responseStatusMessage={props.fetchResponseMessage}
        selectedRows={props.selectedRows}
        sortedBy={props.orderBy}
        sortDescending={props.sortDescending}
        uidProperty={props.uidProperty}
        useHoverableRows={true}
        useStripedRows={false}
      />
    </>
  );
}

export default AEDChecklistTable;
