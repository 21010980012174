/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Form from "@ukhc/devops-react-library/components/Form";
import ResponseStatusAlert from "@ukhc/devops-react-library/components/ResponseStatusAlert";

import { DateTime } from "luxon";

function AEDChecklistUpdateCard({ ...props }) {
  const locations = props.locations;

  const [requireMissingAEDNoteField, setRequireMissingAEDNoteField] =
    useState(false);

  const [fetchResponse, setFetchResponse] = useState({
    statusCode: null,
    message: null,
  });

  const [duplicateInspectionFound, setDuplicateInspectionFound] =
    useState(false);

  const record = {
    unit: props.selectedUnit ? String(props.selectedUnit.value) : "",
    location: props.selectedLocation
      ? String(props.selectedLocation.value)
      : "",
  };

  const [primaryInspectionDetails, setPrimaryInspectionDetails] = useState({
    date: null,
    location: locations.find(
      (location) => String(location.value) === String(record.location)
    ).label,
    unit: record.unit,
  });

  useEffect(() => {
    if (
      primaryInspectionDetails.location !== "" &&
      primaryInspectionDetails.unit !== "" &&
      primaryInspectionDetails.date !== null
    ) {
      checkForDuplicateInspection();
    }
  }, [primaryInspectionDetails]);

  async function checkForDuplicateInspection() {
    if (window.REACT_APP_DEBUG)
      console.log("AEDChecklistUpdateCard: checkForDuplicateInspection");

    const fetchUrl = new URL(window.REACT_APP_API_URL + "/aedchecklists");

    // Filtering
    if (primaryInspectionDetails.location !== "") {
      fetchUrl.searchParams.set(
        "location",
        encodeURIComponent(primaryInspectionDetails.location)
      );
    } else {
      fetchUrl.searchParams.delete("location");
    }

    if (primaryInspectionDetails.unit !== "") {
      fetchUrl.searchParams.set(
        "unit",
        encodeURIComponent(primaryInspectionDetails.unit)
      );
    } else {
      fetchUrl.searchParams.delete("unit");
    }

    // Filter by date
    if (primaryInspectionDetails.date !== null) {
      fetchUrl.searchParams.set(
        "date",
        encodeURIComponent(primaryInspectionDetails.date)
      );
    } else {
      fetchUrl.searchParams.delete("date");
    }

    fetchUrl.toString();

    if (window.REACT_APP_DEBUG) console.log("Records: " + fetchUrl);

    const fetchOptions = {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        throw new Error(response.status);
      }

      const data = await response.json();
      if (data.length > 0) {
        setDuplicateInspectionFound(true);
      }
    } catch (error) {
      if (window.REACT_APP_DEBUG) console.log("Records error: " + error);
      if (window.REACT_APP_DEBUG) console.dir(error);

      // Handle missing API (no response)
      if (error.message && error.message === "404") {
        setDuplicateInspectionFound(false);
      }
    }
  }

  var formControls = {
    rows: [
      {
        cols: [
          {
            controls: [
              {
                type: "custom",
                component: (
                  <div
                    className={`${
                      duplicateInspectionFound ? "d-block" : "d-none"
                    } alert alert-danger mb-2 py-1`}
                  >
                    <small>Only one inspection can be submitted per day.</small>
                  </div>
                ),
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                id: "date",
                label: "Date",
                property: "date",
                type: "date",
                required: true,
                defaultValue: primaryInspectionDetails.date,
              },
            ],
          },
          {
            controls: [
              {
                id: "aedManufacturer",
                label: "AED Manufacturer",
                property: "aedManufacturer",
                type: "text",
                maxLength: 35,
                required: true,
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                label: "Model Number",
                property: "modelNumber",
                id: "modelNumber",
                type: "text",
                maxLength: 35,
                required: true,
                disabled: duplicateInspectionFound,
              },
            ],
          },
          {
            controls: [
              {
                label: "Serial Number",
                property: "serialNumber",
                id: "serialNumber",
                type: "text",
                maxLength: 35,
                required: true,
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                label: "Location",
                property: "location",
                id: "location",
                type: "select",
                options: props.locations,
                required: true,
              },
            ],
          },
          {
            controls: [
              {
                label: "Unit",
                property: "unit",
                id: "unit",
                type: "select",
                options: props.units,
                disabled: props.isFetchingUnits,
                required: true,
              },
            ],
          },
        ],
      },
      {
        cols: [
          {
            controls: [
              {
                label: "Is AED Missing?",
                property: "isAEDMissing",
                id: "isAEDMissing",
                type: "select",
                required: true,
                options: [
                  { label: "No", value: "false" },
                  { label: "Yes", value: "true" },
                ],
                disabled: duplicateInspectionFound,
              },
            ],
          },
          {
            controls: [
              {
                label: "Note here if AED is missing",
                property: "missingAEDNote",
                id: "missingAEDNote",
                type: "text",
                maxLength: 35,
                required: requireMissingAEDNoteField,
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
    ],
  };

  formControls.rows.push({
    cols: [
      {
        label: "Criteria",
      },
    ],
  });
  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "cleanliness",
            id: "cleanlinessCompliance",
            label: "Clean and free of damage",
            type: "radio",
            required: true,
            name: "cleanlinessCompliance",
            inline: true,
            options: [
              {
                id: "cleanlinessCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "cleanlinessNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "cleanlinessNote",
            disabled: duplicateInspectionFound,
            id: "cleanlinessNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });
  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "visibility",
            id: "visibilityCompliance",
            label: "Placement visible, unobstructed, and near phone",
            type: "radio",
            required: true,
            name: "visibilityCompliance",
            inline: true,
            options: [
              {
                id: "visibilityCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "visibilityNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "visibilityNote",
            disabled: duplicateInspectionFound,
            id: "visibilityNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });
  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "battery",
            id: "batteryCompliance",
            label: "Verify battery in place",
            type: "radio",
            required: true,
            name: "batteryCompliance",
            inline: true,
            options: [
              {
                id: "batteryCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "batteryNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "batteryNote",
            disabled: duplicateInspectionFound,
            id: "batteryNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });
  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "readiness",
            id: "readinessCompliance",
            label: "Verify unit is ready to use",
            type: "radio",
            required: true,
            name: "readinessCompliance",
            inline: true,
            options: [
              {
                id: "readinessCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "readinessNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "readinessNote",
            disabled: duplicateInspectionFound,
            id: "readinessNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });
  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "serviceAlarmAbsence",
            id: "serviceAlarmAbsenceCompliance",
            label: "Note absence of visual/audible service alarm",
            type: "radio",
            required: true,
            name: "serviceAlarmAbsenceCompliance",
            inline: true,
            options: [
              {
                id: "serviceAlarmAbsenceCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "serviceAlarmAbsenceNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            id: "serviceAlarmAbscenceNote",
            disabled: duplicateInspectionFound,
            property: "serviceAlarmAbscenceNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });
  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "cabinetSecurity",
            id: "cabinetSecurityCompliance",
            label: "AED secured in wall cabinet",
            type: "radio",
            required: true,
            name: "cabinetSecurityCompliance",
            inline: true,
            options: [
              {
                id: "cabinetSecurityCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "cabinetSecurityNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "cabinetSecurityNote",
            disabled: duplicateInspectionFound,
            id: "cabinetSecurityNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });

  // CC-23 - delete annualCheckDateVerification - begin
  /*
  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "annualCheckDateVerification",
            id: "annualCheckDateVerificationCompliance",
            label: "Verify date of annual check (found on Crothall tag)",
            type: "radio",
            required: true,
            name: "annualCheckDateVerificationCompliance",
            inline: true,
            options: [
              {
                id: "annualCheckDateVerificationCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "annualCheckDateVerificationNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "annualCheckDateVerificationNote",
            disabled: duplicateInspectionFound,
            id: "annualCheckDateVerificationNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });
  */
  // CC-23 - delete annualCheckDateVerification - end

  formControls.rows.push({
    cols: [
      {
        label: "Supplies",
      },
    ],
  });

  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            component: (
              <>
                <p
                  className="text-dark font-weight-bold mb-1"
                  style={{ fontSize: 13 }}
                >
                  One set of AED pads in sealed package*
                </p>
                <ul
                  className="text-dark font-weight-bold mb-0"
                  style={{ fontSize: 13 }}
                >
                  <li>Two sets of AED pads in sealed packages (optional)</li>
                  <li>
                    Pads for monthly check-off should be kept in a separate
                    location
                  </li>
                </ul>
              </>
            ),
            property: "sealedAEDPadsText",
            type: "custom",
          },
          {
            property: "sealedAEDPads",
            id: "sealedAEDPadsCompliance",
            label: "",
            showLabel: false,
            type: "radio",
            required: true,
            name: "sealedAEDPadsCompliance",
            inline: true,
            options: [
              {
                id: "sealedAEDPadsCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "sealedAEDPadsNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "sealedAEDPadsNote",
            disabled: duplicateInspectionFound,
            id: "sealedAEDPadsNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });

  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "noPadsExpired",
            id: "noPadsExpiredCompliance",
            label: "No pads past expiration date and replace if expired",
            type: "radio",
            required: true,
            name: "noPadsExpiredCompliance",
            inline: true,
            options: [
              {
                id: "noPadsExpiredCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "noPadsExpiredNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "noPadsExpiredNote",
            disabled: duplicateInspectionFound,
            id: "noPadsExpiredNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });

  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "aedKitAvailable",
            id: "aedKitAvailableCompliance",
            label:
              "AED resuscitation kit available (examination gloves, razor, scissors, gauze/towel)",
            type: "radio",
            required: true,
            name: "aedKitAvailableCompliance",
            inline: true,
            options: [
              {
                id: "aedKitAvailableCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "aedKitAvailableNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "aedKitAvailableNote",
            disabled: duplicateInspectionFound,
            id: "aedKitAvailableNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });

  formControls.rows.push({
    cols: [
      {
        controls: [
          {
            property: "pocketMaskPresent",
            id: "pocketMaskPresentCompliance",
            label: "Pocket mask with one-way valve present",
            type: "radio",
            required: true,
            name: "pocketMaskPresentCompliance",
            inline: true,
            options: [
              {
                id: "pocketMaskPresentCompliant",
                label: "Compliant",
                value: "true",
                disabled: duplicateInspectionFound,
              },
              {
                id: "pocketMaskPresentNonCompliant",
                label: "Non-Compliant",
                value: "false",
                disabled: duplicateInspectionFound,
              },
            ],
          },
        ],
      },
      {
        controls: [
          {
            property: "pocketMaskPresentNote",
            disabled: duplicateInspectionFound,
            id: "pocketMaskPresentNote",
            label: "Corrective Action/Comments",
            type: "text",
            maxLength: 35,
          },
        ],
      },
    ],
  });

  async function handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
      return;
    } else {
      form.classList.add("was-validated");
    }

    const fetchUrl = window.REACT_APP_API_URL + "/aedchecklists";

    const fetchOptions = {
      method: "POST",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
      body: JSON.stringify({
        date: event.target.date.value,
        aedManufacturer: event.target.aedManufacturer.value,
        serialNumber: event.target.serialNumber.value,
        modelNumber: event.target.modelNumber.value,
        unit: event.target.unit.value,
        location: event.target.location.selectedOptions[0].label,
        isAEDMissing: event.target.isAEDMissing.value === "true" ? true : false,
        missingAEDNote: String(event.target.missingAEDNote.value),
        cleanliness: event.target.cleanliness.value === "true" ? true : false,
        cleanlinessNote: String(event.target.cleanlinessNote.value),
        visibility: event.target.visibility.value === "true" ? true : false,
        visibilityNote: String(event.target.visibilityNote.value),
        battery: event.target.battery.value === "true" ? true : false,
        batteryNote: String(event.target.batteryNote.value),
        readiness: event.target.readiness.value === "true" ? true : false,
        readinessNote: String(event.target.readinessNote.value),
        serviceAlarmAbsence:
          event.target.serviceAlarmAbsence.value === "true" ? true : false,
        serviceAlarmAbscenceNote: String(
          event.target.serviceAlarmAbscenceNote.value
        ),
        cabinetSecurity:
          event.target.cabinetSecurity.value === "true" ? true : false,
        cabinetSecurityNote: String(event.target.cabinetSecurityNote.value),
        // CC-23 delete annualCheckDateVerification - begin
        /*
        annualCheckDateVerification:
          event.target.annualCheckDateVerification.value === "true"
            ? true
            : false,
        annualCheckDateVerificationNote: String(
          event.target.annualCheckDateVerificationNote.value
        ),
        */
        // CC-23 delete annualCheckDateVerification - end
        sealedAEDPads:
          event.target.sealedAEDPads.value === "true" ? true : false,
        sealedAEDPadsNote: String(event.target.sealedAEDPadsNote.value),
        noPadsExpired:
          event.target.noPadsExpired.value === "true" ? true : false,
        noPadsExpiredNote: String(event.target.noPadsExpiredNote.value),
        aedKitAvailable:
          event.target.aedKitAvailable.value === "true" ? true : false,
        aedKitAvailableNote: String(event.target.aedKitAvailableNote.value),
        pocketMaskPresent:
          event.target.pocketMaskPresent.value === "true" ? true : false,
        pocketMaskPresentNote: String(event.target.pocketMaskPresentNote.value),
        dateTimeSubmitted: DateTime.now(),
      }),
    };

    if (duplicateInspectionFound) {
      setFetchResponse({
        statusCode: 400,
        message: "Only one inspection can be submitted per day.",
      });
    } else {
      try {
        const response = await fetch(fetchUrl, fetchOptions);
        const data = await response.json();

        if (!response.ok) {
          setFetchResponse({ statusCode: response.status, message: null });
          throw Error(response.status);
        }

        setFetchResponse({
          statusCode: response.status,
          message: null,
        });
        handleCloseForm();

        return data;
      } catch (error) {
        if (window.REACT_APP_DEBUG)
          console.log("AED Checklist Add error: " + error);
        if (window.REACT_APP_DEBUG) console.dir(error);
      }
    }
  }

  function handleCloseForm() {
    props.onHide();
  }

  function handleFormDataChange(property, value) {
    if (property === "isAEDMissing") {
      setRequireMissingAEDNoteField(value === "true");
    }

    if (property === "location") {
      props.handleFetchUnits("aedchecklists", value);
      let selectedLocation = locations.find(
        (location) => String(location.value) === String(value)
      );
      setPrimaryInspectionDetails((details) => ({
        ...details,
        location: selectedLocation.label,
      }));
    }

    if (property === "unit") {
      setPrimaryInspectionDetails((details) => ({
        ...details,
        unit: value,
      }));
    }

    if (property === "date") {
      setPrimaryInspectionDetails((details) => ({
        ...details,
        date: value,
      }));
    }
  }

  return (
    <div className="card mb-3" id="aedChecklistAddCard">
      <div className="modal-content">
        <h5 className="card-header">Add AED Checklist Inspection</h5>
        <div className="card-body">
          <Form
            id="aedChecklistAdd-form"
            onChange={handleFormDataChange}
            controls={formControls}
            onCancel={handleCloseForm}
            onSubmit={handleSubmit}
            submitButtonLabel="Submit"
            isSubmitButtonEnabled={!duplicateInspectionFound}
            record={record}
          />
          {fetchResponse.statusCode > 399 && (
            <ResponseStatusAlert
              statusCode={fetchResponse.statusCode}
              message={fetchResponse.message}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AEDChecklistUpdateCard;
