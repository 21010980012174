import { DateTime } from "luxon";

function MonthYearSelect({ ...props }) {
  const date = DateTime.now();

  const currentYear = date.year;
  const lastYear = date.minus({ years: 1 }).year;
  const nextYear = date.plus({ years: 1 }).year;

  const years = [lastYear, currentYear, nextYear];
  const months = [
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];

  function handleOnChange(property, event) {
    props.onChange(property, event.target.value);
  }

  return (
    <div className="row d-flex align-items-end">
      <div className="col">
        <div className="form-group">
          <label htmlFor={props.id + "DateMonth"}>
            <span
              className="text-dark font-weight-bold"
              style={{ fontSize: 13, letterSpacing: 0.25 }}
            >
              {props.label}
              {props.required ? "*" : ""}
            </span>
          </label>
          <select
            className="form-control form-control-sm"
            id={props.id + "DateMonth"}
            onChange={(e) => handleOnChange(props.id + "Month", e)}
            disabled={props.disabled}
            required={props.required}
          >
            <option value="">Select month</option>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="col">
        <div className="form-group">
          <select
            className="form-control form-control-sm"
            id={props.id + "FromYear"}
            onChange={(e) => handleOnChange(props.id + "Year", e)}
            disabled={props.disabled}
            required={props.required}
          >
            <option value="">Select year</option>
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default MonthYearSelect;
