/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import $ from "jquery";
// @ukhc/devops-react-library
import Pagination from "@ukhc/devops-react-library/components/Pagination";
import ItemsPerPage from "@ukhc/devops-react-library/components/ItemsPerPage";
// App components
import Select from "react-select";
import AEDChecklistUpdateCard from "./AEDChecklistUpdateCard";
import AEDChecklistTable from "./AEDChecklistTable";
import EmergencySupplyChecklistTable from "./EmergencySupplyChecklistTable";
import EmergencySupplyChecklistUpdateCard from "./EmergencySupplyChecklistUpdateCard";
import GenerateReportModal from "./GenerateReportModal";

import { DateTime } from "luxon";

function Records({ ...props }) {
  const fromDate = DateTime.now().startOf("month").toISODate();
  const toDate = DateTime.now().endOf("month").toISODate();

  const checklistOptions = [
    { value: "aedchecklists", label: "AED Checklist" },
    { value: "emergencysupplychecklists", label: "Emergency Supply Checklist" },
  ];

  const aedChecklistColumns = [
    {
      name: "Date",
      property: "dateFormatted",
    },
    {
      name: "AED Manufacturer",
      property: "aedManufacturer",
    },
    {
      name: "Model #",
      property: "modelNumber",
    },
    {
      name: "Serial #",
      property: "serialNumber",
    },
    {
      name: "Employee Name",
      property: "employeeName",
    },
    {
      name: "Employee Linkblue",
      property: "employeeLinkLinkBlueId",
    },
    {
      name: "Employee Title",
      property: "employeeTitle",
    },
    {
      name: "Submitted",
      property: "dateTimeSubmittedFormatted",
    },
  ];

  // TODO: Update columns
  const emergencyChecklistColumns = [
    {
      name: "Date",
      property: "dateFormatted",
    },
    {
      name: "Operation",
      property: "operation",
    },
    {
      name: "Shift",
      property: "shift",
    },
    // CC-24 - delete zollPropertyTag - begin
    /*
    {
      name: "Zoll property tag #",
      property: "zollPropertyTag",
    },
    */
    // CC-24 - delete zollPropertyTag - end
    // CC-24 - add recordPropertyTag - begin
    {
      name: "Record Property tag #",
      property: "recordPropertyTag",
    },
    // CC-24 - add recordPropertyTag - end
    {
      name: "Serial #",
      property: "codeCartSerialNumber",
    },
    {
      name: "Exp. Date of Cart",
      property: "cartExpirationDateFormatted",
    },
    {
      name: "Employee Name",
      property: "employeeName",
    },
    {
      name: "Employee Linkblue",
      property: "employeeLinkLinkBlueId",
    },
    {
      name: "Employee Title",
      property: "employeeTitle",
    },
    {
      name: "Submitted",
      property: "dateTimeSubmittedFormatted",
    },
  ];

  const [table, setTable] = useState({
    currentPage: 1,
    isTableDisabled: true,
    itemsPerPage: 50,
    itemsPerPageOptions: [1, 5, 10, 25, 50],
    orderBy: "id",
    selectedRecord: {},
    selectedRows: [],
    sortDescending: false,
    totalItems: null,
    totalPages: null,
    uidProperty: "id",
  });
  const [checklistFetchResponse, setChecklistFetchResponse] = useState({
    statusCode: null,
    message: null,
  });
  const [unitsFetchResponse, setUnitsFetchResponse] = useState({
    statusCode: null,
  });
  const [locationsFetchResponse, setLocationsFetchResponse] = useState({
    statusCode: null,
  });
  const [isFetchingChecklistRecords, setIsFetchingChecklistRecords] =
    useState(true);
  const [isFetchingLocations, setIsFetchingLocations] = useState(false);
  const [isFetchingUnits, setIsFetchingUnits] = useState(false);
  const [checklistRecords, setChecklistRecords] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState("");
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [units, setUnits] = useState([]);
  const [locations, setLocations] = useState([]);

  // TODO: Update names
  const [aedChecklistAddModalShow, setAedChecklistAddModalShow] =
    useState(false);
  const [
    emergencySupplyChecklistAddModalShow,
    setEmergencySupplyChecklistAddModalShow,
  ] = useState(false);

  const [generateReportModalShow, setGenerateReportModalShow] = useState(false);

  useEffect(() => {
    // Initial modal state
    $(".modal-backdrop").remove();
  }, []);

  useEffect(() => {
    if (
      selectedChecklist !== "" &&
      selectedLocation !== null &&
      selectedUnit !== null &&
      !aedChecklistAddModalShow
    ) {
      if (window.REACT_APP_DEBUG) console.log("calling handleFetch()");
      handleFetchChecklistRecords(selectedChecklist);
    }
  }, [
    table.currentPage,
    table.itemsPerPage,
    selectedUnit,
    aedChecklistAddModalShow,
    emergencySupplyChecklistAddModalShow,
  ]);

  useEffect(() => {
    if (selectedChecklist !== "") {
      handleFetchLocations(selectedChecklist);
    }
  }, [selectedChecklist]);

  useEffect(() => {
    if (selectedChecklist !== "" && selectedLocation !== null) {
      handleFetchUnits(selectedChecklist, selectedLocation.value);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (aedChecklistAddModalShow) {
      $("#aedChecklistAddModal").modal("show");
    } else {
      $("#aedChecklistAddModal").modal("hide");
    }
  }, [aedChecklistAddModalShow]);

  useEffect(() => {
    if (emergencySupplyChecklistAddModalShow) {
      $("#emergencySupplyChecklistAddModal").modal("show");
    } else {
      $("#emergencySupplyChecklistAddModal").modal("hide");
    }
  }, [emergencySupplyChecklistAddModalShow]);

  useEffect(() => {
    if (generateReportModalShow) {
      $("#generateReportModal").modal("show");
    } else {
      $("#generateReportModal").modal("hide");
    }
  }, [generateReportModalShow]);

  async function handleFetchUnits(checklistName, location) {
    if (window.REACT_APP_DEBUG) {
      console.log("getting units...");
    }
    setIsFetchingUnits(true);
    let pathname = `/${checklistName}/units/${location}`;
    const fetchUrl = new URL(window.REACT_APP_API_URL + pathname);
    const fetchOptions = {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
    };
    fetchUrl.toString();
    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        setUnitsFetchResponse({
          statusCode: response.status,
        });
        throw new Error(response.status);
      }

      setUnitsFetchResponse({
        statusCode: response.status,
      });

      const data = await response.json();
      let optionsArray = [];
      data.forEach((unit) => {
        optionsArray.push({ value: unit, label: unit });
      });

      setUnits(optionsArray);
      setIsFetchingUnits(false);
    } catch (error) {
      if (window.REACT_APP_DEBUG) console.log("Units error: " + error);
      if (window.REACT_APP_DEBUG) console.dir(error);

      // Handle missing API (no response)
      if (error.message && error.message === "Failed to fetch") {
        setUnitsFetchResponse({
          statusCode: 404,
        });
      }
      setIsFetchingUnits(false);
      setUnits([]);
    }
  }

  async function handleFetchLocations(checklistName) {
    if (window.REACT_APP_DEBUG) {
      console.log("getting locations...");
    }
    setIsFetchingLocations(true);
    let pathname = `/${checklistName}/locations`;
    const fetchUrl = new URL(window.REACT_APP_API_URL + pathname);
    const fetchOptions = {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
    };
    fetchUrl.toString();
    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        setLocationsFetchResponse({
          statusCode: response.status,
        });
        throw new Error(response.status);
      }

      setLocationsFetchResponse({
        statusCode: response.status,
      });

      const data = await response.json();
      let optionsArray = [];
      data.forEach((location) => {
        optionsArray.push({ value: location.id, label: location.location });
      });

      setLocations(optionsArray);
      setIsFetchingLocations(false);
    } catch (error) {
      if (window.REACT_APP_DEBUG) console.log("Locations error: " + error);
      if (window.REACT_APP_DEBUG) console.dir(error);

      // Handle missing API (no response)
      if (error.message && error.message === "Failed to fetch") {
        setLocationsFetchResponse({
          statusCode: 404,
        });
      }
      setIsFetchingLocations(false);
      setLocations([]);
    }
  }

  async function handleFetchChecklistRecords(checklistName) {
    if (window.REACT_APP_DEBUG) console.log("Records: handleFetch");

    setIsFetchingChecklistRecords(true);

    const fetchUrl = new URL(`${window.REACT_APP_API_URL}/${checklistName}`);
    fetchUrl.searchParams.set("pageSize", String(table.itemsPerPage));
    fetchUrl.searchParams.set("currentPage", table.currentPage);

    // Sorting
    fetchUrl.searchParams.set("orderBy", table.orderBy);
    if (table.sortDescending) {
      fetchUrl.searchParams.set("orderByDirection", "desc");
    } else {
      fetchUrl.searchParams.delete("orderByDirection");
    }

    // Filtering
    if (selectedLocation !== null) {
      fetchUrl.searchParams.set("location", selectedLocation.label);
    } else {
      fetchUrl.searchParams.delete("location");
    }

    if (selectedUnit !== null) {
      fetchUrl.searchParams.set("unit", selectedUnit.label);
    } else {
      fetchUrl.searchParams.delete("unit");
    }

    // Filter by month
    fetchUrl.searchParams.set("fromDate", fromDate);
    fetchUrl.searchParams.set("toDate", toDate);

    fetchUrl.toString();

    if (window.REACT_APP_DEBUG) console.log("Records: " + fetchUrl);

    const fetchOptions = {
      method: "GET",
      headers: {
        ACCEPT: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.apiToken.accessToken}`,
      },
    };

    try {
      const response = await fetch(fetchUrl, fetchOptions);

      if (!response.ok) {
        setChecklistFetchResponse({
          statusCode: response.status,
          message: null,
        });
        throw new Error(response.status);
      }

      if (window.REACT_APP_DEBUG)
        console.log("Records fetch response.status: " + response.status);

      // Read pagination from the header
      let pagination = JSON.parse(response.headers.get("x-pagination"));
      setTable((table) => ({
        ...table,
        currentPage: pagination.currentPage,
        totalPages: pagination.totalPages,
        totalItems: pagination.totalItems,
      }));
      setChecklistFetchResponse({
        statusCode: response.status,
        message: null,
      });

      const data = await response.json();

      data.forEach((element) => {
        element.dateFormatted = DateTime.fromISO(element.date).toFormat("D");
        element.dateTimeSubmittedFormatted = DateTime.fromISO(
          element.dateTimeSubmitted
        ).toFormat("D");

        if (element.cartExpirationDate) {
          element.cartExpirationDateFormatted = DateTime.fromISO(
            element.cartExpirationDate
          ).toFormat("D");
        }
      });

      setIsFetchingChecklistRecords(false);
      setTable((table) => ({
        ...table,
        isTableDisabled: false,
      }));
      setChecklistRecords(data || []);
    } catch (error) {
      if (window.REACT_APP_DEBUG) console.log("Records error: " + error);
      if (window.REACT_APP_DEBUG) console.dir(error);

      // Handle missing API (no response)
      if (error.message && error.message === "404") {
        setChecklistFetchResponse({
          statusCode: 404,
          message: "No inspections found.",
        });
      }
      setIsFetchingChecklistRecords(false);
      setTable((table) => ({
        ...table,
        isTableDisabled: false,
        totalPages: 0,
        totalItems: 0,
      }));
      setChecklistRecords([]);
    }
  }

  function handleSetPage(newPage) {
    setTable((table) => ({
      ...table,
      currentPage: newPage,
    }));
  }

  function handleSetItemsPerPage(newLimit) {
    setTable((table) => ({
      ...table,
      itemsPerPage: Number(newLimit),
      currentPage: 1,
    }));
  }

  function handleSetSelectedRows(rows) {
    if (rows) {
      var selectedRecord = {};
      if (rows.length > 0) {
        selectedRecord = checklistRecords.filter(function (record) {
          return record.id === rows[0].uid;
        })[0];
      }
      if (window.REACT_APP_DEBUG)
        console.log("handleSetSelectedRows:" + selectedRecord);
      setTable((table) => ({
        ...table,
        selectedRows: rows,
        selectedRecord: selectedRecord,
      }));
    } else {
      setTable((table) => ({
        ...table,
        selectedRows: [],
        selectedRecord: {},
      }));
    }
  }

  // get the selected records into an array
  var selectedRecords = [];
  for (const row of table.selectedRows) {
    let rowToAdd = checklistRecords.filter(
      (record) => record[table.uidProperty] === row.uid
    )[0];
    if (rowToAdd) {
      selectedRecords.push(rowToAdd);
    }
  }

  var resultsCountString = "";
  if (table.totalItems > 0) {
    resultsCountString =
      table.currentPage * table.itemsPerPage -
      (table.itemsPerPage - 1) +
      "-" +
      (table.currentPage * table.itemsPerPage -
        table.itemsPerPage +
        checklistRecords.length) +
      " of " +
      table.totalItems;
  } else {
    resultsCountString = "0 items";
  }

  function handleSetSelectedChecklist(option) {
    if (window.REACT_APP_DEBUG) console.log("checklist option", option);
    if (option) {
      setSelectedChecklist(option.value);
      setSelectedLocation(null);
      setSelectedUnit(null);
    }
  }

  function handleSetSelectedUnit(option) {
    if (window.REACT_APP_DEBUG) console.log("unit option", option);
    if (option) {
      setSelectedUnit({
        value: option.value,
        label: option.label,
      });
    }
  }

  function handleSetSelectedLocation(option) {
    if (window.REACT_APP_DEBUG) console.log("location option", option);
    if (option) {
      setSelectedLocation({
        value: option.value,
        label: option.label,
      });
      setSelectedUnit(null);
    }
  }

  function handleAddInspectionClick() {
    if (window.REACT_APP_DEBUG) console.log("handleAddInspectionClick");
    if (selectedChecklist === "aedchecklists") {
      setAedChecklistAddModalShow(true);
    } else if (selectedChecklist === "emergencysupplychecklists") {
      setEmergencySupplyChecklistAddModalShow(true);
    }
  }

  function handleGenerateReportClick() {
    if (window.REACT_APP_DEBUG) console.log("handleGenerateReportClick");
    setGenerateReportModalShow(true);
  }

  function handleAedChecklistAddModalClose() {
    setAedChecklistAddModalShow(false);
  }

  function handleEmergencySupplyChecklistAddModalClose() {
    setEmergencySupplyChecklistAddModalShow(false);
  }

  function handleGenerateReportModalClose() {
    setGenerateReportModalShow(false);
  }

  const customSelectStyles = {
    option: (provided) => ({
      ...provided,
      fontSize: 14,
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: 14,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 14,
    }),
  };

  let tableHeightOffset = 100;

  if (props.windowWidth < 575) {
    tableHeightOffset = 390;
  } else if (props.windowWidth < 992) {
    tableHeightOffset = 350;
  } else {
    tableHeightOffset = 315;
  }

  return (
    <div className="container-fluid">
      <div className="row mb-3">
        <div className="col">
          <div className="card mt-lg-3">
            <div className="card-body py-3">
              <div className="row">
                <div className="col-12 col-sm">
                  <div className="row">
                    <Select
                      className="col mb-2 mb-md-0"
                      isSearchable={true}
                      onChange={handleSetSelectedChecklist}
                      options={checklistOptions}
                      placeholder="Select checklist"
                      styles={customSelectStyles}
                    />
                    <Select
                      className="col mb-2 mb-md-0"
                      isDisabled={
                        selectedChecklist === "" ||
                        locationsFetchResponse.statusCode > 399 ||
                        isFetchingLocations
                      }
                      key={`select_location_key__${
                        selectedLocation ? selectedLocation.value : "undefined"
                      }`}
                      value={selectedLocation}
                      isLoading={isFetchingLocations}
                      isSearchable={true}
                      onChange={handleSetSelectedLocation}
                      options={locations}
                      placeholder="Select location"
                      styles={customSelectStyles}
                    />
                    <Select
                      className="col mb-2 mb-sm-0"
                      isDisabled={
                        selectedChecklist === "" ||
                        selectedLocation === null ||
                        unitsFetchResponse.statusCode > 399 ||
                        isFetchingUnits
                      }
                      key={`select_unit_key__${
                        selectedUnit ? selectedUnit.value : "undefined"
                      }`}
                      value={selectedUnit || ""}
                      isLoading={isFetchingUnits}
                      isSearchable={true}
                      onChange={handleSetSelectedUnit}
                      options={units}
                      placeholder="Select unit"
                      styles={customSelectStyles}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-4 text-right">
                  {props.user.isAdministrator && (
                    <button
                      type="button"
                      id="genReportButton"
                      className="btn btn-link btn-sm"
                      onClick={handleGenerateReportClick}
                      style={{ fontSize: 13 }}
                      disabled={
                        aedChecklistAddModalShow ||
                        emergencySupplyChecklistAddModalShow
                      }
                    >
                      <i className="bi bi-cloud-download"></i> Generate Report
                    </button>
                  )}

                  <button
                    type="button"
                    id="addCheckButton"
                    className="btn btn-link btn-sm"
                    disabled={
                      selectedChecklist === "" ||
                      selectedLocation === null ||
                      selectedUnit === null ||
                      aedChecklistAddModalShow ||
                      emergencySupplyChecklistAddModalShow
                    }
                    onClick={handleAddInspectionClick}
                    style={{ fontSize: 13 }}
                  >
                    <i className="bi bi-plus-circle"></i> Add inspection
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedChecklist !== "" &&
        selectedLocation !== null &&
        selectedUnit !== null &&
        !isFetchingLocations &&
        !isFetchingUnits &&
        !isFetchingChecklistRecords &&
        !(aedChecklistAddModalShow || emergencySupplyChecklistAddModalShow) && (
          <div className="row">
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div
                      className="col overflow-auto"
                      style={
                        props.fullHeight
                          ? {}
                          : {
                              height: `calc(100vh - ${tableHeightOffset}px)`,
                            }
                      }
                    >
                      {selectedChecklist === "aedchecklists" && (
                        <AEDChecklistTable
                          tableColumns={aedChecklistColumns}
                          isTableDisabled={table.isTableDisabled}
                          // handleSetSelectedRows={handleSetSelectedRows}
                          isLoading={
                            isFetchingChecklistRecords ||
                            isFetchingLocations ||
                            isFetchingUnits
                          }
                          records={checklistRecords}
                          fetchResponseStatusCode={
                            checklistFetchResponse.statusCode
                          }
                          fetchResponseMessage={checklistFetchResponse.message}
                          selectedRows={table.selectedRows}
                          orderBy={table.orderBy}
                          sortDescending={table.sortDescending}
                          uidProperty={table.uidProperty}
                        />
                      )}
                      {selectedChecklist === "emergencysupplychecklists" && (
                        <EmergencySupplyChecklistTable
                          tableColumns={emergencyChecklistColumns}
                          isTableDisabled={table.isTableDisabled}
                          // handleSetSelectedRows={handleSetSelectedRows}
                          isLoading={
                            isFetchingChecklistRecords ||
                            isFetchingLocations ||
                            isFetchingUnits
                          }
                          records={checklistRecords}
                          fetchResponseStatusCode={
                            checklistFetchResponse.statusCode
                          }
                          fetchResponseMessage={checklistFetchResponse.message}
                          selectedRows={table.selectedRows}
                          orderBy={table.orderBy}
                          sortDescending={table.sortDescending}
                          uidProperty={table.uidProperty}
                        />
                      )}
                    </div>
                  </div>
                  <hr className="my-0 py-0" />
                  <div className="row no-gutters">
                    <div className="col mt-1">
                      <ItemsPerPage
                        disabled={
                          table.isTableDisabled ||
                          // exportData.isExporting ||
                          checklistFetchResponse.statusCode > 399
                        }
                        onChange={handleSetItemsPerPage}
                        options={table.itemsPerPageOptions}
                        value={table.itemsPerPage}
                      />
                    </div>
                    <div className="col d-none d-sm-block align-middle">
                      <div>
                        <center>
                          <div className="mt-2 text-muted">
                            <small>{resultsCountString}</small>
                          </div>
                        </center>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-end py-1">
                      <Pagination
                        currentPage={table.currentPage}
                        disabled={
                          table.isTableDisabled ||
                          // exportData.isExporting ||
                          checklistFetchResponse.statusCode > 399
                        }
                        onChange={handleSetPage}
                        totalPages={Math.ceil(
                          table.totalItems / table.itemsPerPage
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {aedChecklistAddModalShow && (
        <AEDChecklistUpdateCard
          onHide={handleAedChecklistAddModalClose}
          record={table.selectedRecord}
          show={aedChecklistAddModalShow}
          handleFetchUnits={handleFetchUnits}
          isFetchingUnits={isFetchingUnits}
          user={props.user}
          units={units}
          locations={locations}
          selectedLocation={selectedLocation}
          selectedUnit={selectedUnit}
        />
      )}
      {emergencySupplyChecklistAddModalShow && (
        <EmergencySupplyChecklistUpdateCard
          onHide={handleEmergencySupplyChecklistAddModalClose}
          record={table.selectedRecord}
          show={emergencySupplyChecklistAddModalShow}
          handleFetchUnits={handleFetchUnits}
          isFetchingUnits={isFetchingUnits}
          user={props.user}
          units={units}
          locations={locations}
          selectedLocation={selectedLocation}
          selectedUnit={selectedUnit}
        />
      )}
      {generateReportModalShow && (
        <GenerateReportModal
          onHide={handleGenerateReportModalClose}
          show={generateReportModalShow}
          user={props.user}
        />
      )}
    </div>
  );
}

export default Records;
